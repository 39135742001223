import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2196f3',
        secondary: '#a075d8',
        accent: '#e57373',
        error: '#d82960',
        warning: '#FB8C00',
        info: '#2196f3',
        success: '#4caf50'
      },
      dark: {
        primary: '#2196f3',
        secondary: '#a075d8',
        accent: '#e57373',
        error: '#d82960',
        warning: '#FB8C00',
        info: '#2196f3',
        success: '#4caf50'
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
})
