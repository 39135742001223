<template>
  <div id="app">
    <Main/>
  </div>
</template>

<script>
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Main
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../public/img/background.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  /* margin-top: 60px; */
}
</style>
