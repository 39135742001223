<template>
  <v-container grid-list-xs,sm,md,lg,xl>
    <v-main row wrap>
      <v-layout row wrap align="left">
        <v-flex xs12>
          <div class="main-title">
            <br><br><br><br><br>
            <span align-left><b>Тахографы</b></span><br>
            <span><b>в Кирове</b></span><br>
            <span><b>от государственного предприятия</b></span><br><br><br><br><br><br>
          </div>
        </v-flex>
        <v-card align="center">
          <v-flex xs12 md10>
            <v-card flat>
              <br><br>
              <v-row>
                <v-col align="center">
                  <v-img src="img/cds_logo.png" max-width="300px"></v-img>
                </v-col>
                <v-col align="left">
                  <v-card flat>
                    <v-card-text>
                      <p>
                        Центральная диспетчерская служба городского пассажирского транспорта
                        основанна в 1972 году. Занимается системами навигации с момента их появления.
                        Обслуживает весь парк пассажирского транспорта.
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <br><br>
              <h1 align="center">Почему у нас?</h1>
              <br><br>

              <v-row>
                <v-col>
                  <v-flex xs12>
                    <v-card flat align="center">
                      <div class="card">
                        <br>
                        <div class="chip"><br>1</div><br>
                        <h3 class="cardtitle">Муниципальное предприятие</h3><br><br><br><br>
                        <span>Гарантия того, что предприятие не закроется, не обанкротится и не исчезнет внезапно.</span>
                        <br><br>
                      </div>
                    </v-card>
                  </v-flex>

                </v-col>
                <v-col>
                  <v-card flat align="center">
                    <div class="card">
                      <br>
                      <div class="chip"><br>2</div><br>
                      <h3 class="cardtitle">Только качественное обслуживание</h3><br><br>
                      <span>Работа с государственными заказами требует высокого качества выполнения работ.</span>
                      <br><br>
                    </div>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat align="center">
                    <div class="card">
                      <br>
                      <div class="chip"><br>3</div><br>
                      <h3 class="cardtitle">Квалифицированные специалисты</h3><br><br>
                      <span>Собственный и постоянный большой штат специально обученных сотрудников.</span>
                      <br><br>
                    </div>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-card flat align="center">
                    <div class="card">
                      <br>
                      <div class="chip"><br>4</div><br>
                      <h3 class="cardtitle">Собственные помещения</h3><br><br>
                      <span>Четырехэтажное здание, складские помещения и гараж в центре города, наличие своей парковки.</span>
                      <br><br>
                    </div>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat align="center">
                    <div class="card">
                      <br>
                      <div class="chip"><br>5</div><br>
                      <h3 class="cardtitle">Своя оборудованная мастерская</h3><br><br>
                      <span>Укомплектована современным оборудованием и инструментом.</span>
                      <br><br>
                    </div>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat align="center">
                    <div class="card">
                      <br>
                      <div class="chip"><br>6</div><br>
                      <h3 class="cardtitle">Опыт работы с навигацией</h3><br><br>
                      <span>С начала ее применения на городском транспорте в 2006 году - гарантия качества.</span>
                      <br><br>
                    </div>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-card flat align="center">
                    <div class="card">
                      <br>
                      <div class="chip"><br>7</div>
                      <h3 class="cardtitle">Официальный договор и документы</h3><br><br>
                      <span>Только официальное оформление всех бухгалтерских документов и договоров.</span>
                    </div>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat align="center">
                    <div class="card">
                      <br>
                      <div class="chip"><br>8</div><br>
                      <h3 class="cardtitle">Лицензии и сертификаты</h3><br><br>
                      <span>Наличие комплекта разрешительных документов от компетентных органов.</span>
                      <br><br>
                    </div>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat align="center">
                    <div class="card">
                      <br>
                      <div class="chip"><br>9</div><br>
                      <h3 class="cardtitle">Круглосуточная техподдержка</h3><br><br>
                      <span>Диспетчерская служба предприятия работает круглосуточно и без выходных.</span>
                      <br><br>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <br>
              <v-divider></v-divider>
              <br><br><br><br><br><br>
              <!-- <h1 align="center">Оставьте телефон и мы Вам позвоним</h1>
              <br><br><br>
              <div align="center" class="form">
                  <v-text-field
                    v-model="form.phone"
                    name="phone"
                    label="Телефон"
                  ></v-text-field><br>
                  <v-text-field
                    v-model="form.fullname"
                    name="fio"
                    label="Имя"
                  ></v-text-field>
                  <v-textarea
                    v-model="form.commentary"
                    name="commentary"
                    label="Комментарий"
                  ></v-textarea>
                  <br>
                  <v-btn rounded x-large color="#D32F2F" @click="sendRequest()">Отправить</v-btn><br><br>
                  <small>Нажимая на кнопку, вы принимаете <a href="#">Положение</a> и <a href="#">Согласие</a> на обработку персональных данных.</small>
              </div>
              <br><br><br> -->
              <h1 align="center">Наши контакты</h1><br><br>
              <!-- <span align="center" class="contact"><a href="tel:+79229123490">+7 (922) 912-34-90</a></span><br><br> -->
              <span align="center" class="contact"><a href="tel:+78332489550">+7 (8332) 48-95-50</a></span><br><br>
              <!-- <span align="center" class="contact"><a href="tel:+78332751930">+7 (8332) 75-19-30</a></span><br><br> -->
              <span align="center" class="contact"><a href="mailto:tex@cds43.ru">tex@cds43.ru</a></span><br><br>
              <br><br><br>
              <v-divider></v-divider>
              <br><br><br>
              <h1 align="center">Наша фотогалерея</h1>
              <br><br><br>
              <v-row>
                <v-col></v-col>
                <v-col>
                  <div style="max-width:500px;margin-left:auto;margin-right:auto;">
                    <v-carousel
                      cycle
                      :show-arrows="false"
                      hide-delimiter-background
                      hide-delimiters
                    >
                      <v-carousel-item
                        cycle
                        v-for="(item,i) in slides"
                        :key="i"
                        :src="item.src"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      >
                      </v-carousel-item>
                    </v-carousel>
                  </div>
                </v-col>
                <v-col></v-col>
              </v-row>
              <br><br><br>
              <h1 align="center">Про тахографы</h1>
              <h3 align="center">Что такое тахограф?</h3>
              <p>
                Тахограф — контрольное устройство, предназначенное для установки
                на грузовые автомобили, автобусы и спецтехнику. Тахографы используются
                для регистрации режима труда и отдыха водителей.
              </p>
              <p>
                В соответствии с требованиями законодательства тахографы должны
                быть установлены на все транспортные средства, принадлежащие
                юридическим лицам и индивидуальным предпринимателям, осуществляющим
                на территории Российской Федерации перевозку пассажиров и грузов.
              </p>
              <p>
                Тахографы устанавливаются на автобусы категорий М2 и М3,
                используемые для перевозки пассажиров, транспортные средства
                категорий N2, N3 с полной массой свыше 3,5 тонн.
              </p>
              <p>
                Наша компания осуществляет продажу, активизацию, установку,
                настройку и ремонт цифровых тахографов.
              </p>
              <p>
                Компания имеет весь пакет разрешительных документов от Министерства
                транспорта РФ и лицензию ФСБ РФ на работу со средствами криптозащиты информации.
              </p>
              <br><br>
              <h3 align="center">Тахограф АТОЛ Drive Smart</h3>
              <br>
              <v-row>
                <v-col></v-col>
                <v-col>
                  <v-img src="img/atol.jpg" max-width="600"></v-img>
                </v-col>
                <v-col></v-col>
              </v-row>
              <p>
                Тахограф АТОЛ Drive Smart – усовершенствованная модель цифрового
                автомобильного тахографа АТОЛ с системой криптозащиты информации (СКЗИ).
              </p>
              <p>
                Тахограф АТОЛ Drive Smart полностью соответствует всем требованиям законодательства Российской Федерации.
              </p>
              <p>Совместим со всеми транспортными средствами.</p>
              <p>
                Является средством измерения.
              </p>
              <p>
                <strong>1. Удобно:</strong>
              </p>
              <p>
                <ul>
                  <li>замена элемента питания производится специализированными мастерскими без вскрытия основного корпуса;</li><br>
                  <li>замена блока СКЗИ осуществляется специализированными мастерскими без вскрытия основного корпуса;</li><br>
                  <li>разъем FAKRA для подключения антенн GPS/ГЛОНАСС и GSM;</li><br>
                  <li>монтажный комплект, съемники европейского образца и набор пломб, а также подробная документация по установке и эксплуатации поставляются в комплекте.</li><br>
                </ul>
              </p>
              <p>
                <strong>2. Функционально:</strong>
              </p>
              <p>
                <ul>
                  <li>слот расширения для установки дополнительного модуля позволяет интегрировать решения по мониторингу, контролю уровня топлива, а также терминалы системы ЭРА-ГЛОНАСС;</li><br>
                  <li>настраиваемая многоцветная подсветка дисплея и клавиатуры;</li><br>
                  <li>USB- и k-line интерфейсы;</li><br>
                  <li>скорость печати до 100 мм/с;</li><br>
                  <li>поддерживает несколько вариантов выгрузки данных, также доступно Android-приложение для выгрузки данных (используются стандартные модули USB-Bluetooth).</li><br>
                </ul>
              </p>
              <p>
                <strong>3. Надежно:</strong>
              </p>
              <p>
                <ul>
                  <li>сертифицирован к установке на транспортные средства, перевозящие опасные грузы;</li><br>
                  <li>совместим со всеми марками и моделями транспортных средств;</li><br>
                  <li>конструкция устойчива к механическим воздействиям, как типовым, так и случайным;</li><br>
                  <li>стойкость к вибрациям с амплитудой 10 мм;</li><br>
                  <li>совместим с напряжением питания 8-36 В;</li><br>
                  <li>потребляет мощность &lt; 1 Вт;</li><br>
                  <li>гарантийный срок составляет 24 месяца с момента ввода тахографа в эксплуатацию.</li><br>
                </ul>
              </p>
              <br>
              <h3 align="center">Поверка тахографа</h3>
              <br><br>
              <p>
                В соответствии с приказом Росстандарта №1658 от 25 декабря 2015 года
                на цифровые тахографы утверждены типы средства измерения и методики их поверки.
                Описание типа средства измерения и утвержденные методики поверки допускают
                поверку тахографа только при наличии в его составе поверенного блока СКЗИ.
                Поверенными блоками СКЗИ тахографы комплектуются на заводах изготовителях с 2016 года,
                как правило, все тахографы выпущенные с этого времени поверяются на заводе,
                а следующая дата поверки указана в паспорте или свидетельстве о поверке.
              </p>
              <p>
                Находящиеся в эксплуатации тахографы, укомплектованные неповеренными
                блоками СКЗИ подлежат поверке в момент проведения очередной замены в
                этих тахографах блока СКЗИ (п. 2,3 Приказа Минтранса РФ № 55).
              </p>
              <p>
                Процесс поверки ранее выпущенного тахографа выглядит таким образом:
                специалисты нашей мастерской демонтируют тахограф, меняют в нем блок
                СКЗИ на новый (поверенный), затем тахограф передается в ЦСМ, где
                проводится его поверка, далее в поверенном тахографе активируется блок СКЗИ,
                тахограф устанавливается на транспортное средство и на последнем
                этапе производится калибровка тахографа.
              </p>
              <br>
              <h3 align="center">Ремонт и обслуживание тахографа</h3>
              <br>
              <p>
                Ремонт и обслуживание тахографов: своевременная забота о безопасности на дорогах.
              </p>
              <p>
                Необходимо регулярно проводить обслуживание тахографов в авторизированных
                компаниях, где квалифицированные специалисты проверяют техническое
                состояние устройств и устраняют любые неполадки системы.
              </p>
              <p>
                Ремонт тахографов осуществляется профессиональными сотрудниками
                соответствующей квалификации с использованием специального, поверенного
                оборудования. Такой подход гарантирует отличное и бесперебойное
                функционирование контролирующего устройства в любых дорожных ситуациях.
              </p>
              <p>
                Важность своевременного и квалифицированного обслуживания тахографов.
                Своевременное профессиональное обслуживание тахографов позволяет эффективно решать следующие задачи:<br><br>
                <ul>
                  <li>обеспечивать высокий уровень безопасности на дорогах;</li><br>
                  <li>обеспечивать правовую защиту водителям;</li><br>
                  <li>объективно оценивать качество вождения;</li><br>
                  <li>корректировать расчеты с клиентами транспортных компаний;</li><br>
                  <li>осуществлять внутренние и международные перевозки.</li><br>
                </ul>
              </p>
              <h3 align="center">Особенности обслуживания и ремонта тахографов.</h3>
              <br>
              <p>
                Наша компания осуществляет обслуживание и ремонт тахографов на отечественных,
                иностранных грузовиках и автобусах. Для обеспечения высокого уровня
                обслуживания и оперативности компания имеет в наличии все необходимые
                запчасти и расходные материалы. А перечень предоставляемых услуг включает в себя:
                <br><br>
                <ul>
                  <li>диагностику тахографа;</li><br>
                  <li>восстановление питания тахографа;</li><br>
                  <li>замену датчика скорости;</li><br>
                  <li>замену спидометра;</li><br>
                  <li>замену антенны ГЛОНАСС/GPS;</li><br>
                  <li>обновление прошивки тахографа;</li><br>
                  <li>замену блока СКЗИ тахографа;</li><br>
                  <li>калибровку тахографа;</li><br>
                  <li>замену картоприемника тахографа;</li><br>
                  <li>замену дисплея тахографа;</li><br>
                  <li>замену принтера тахграфа;</li><br>
                  <li>замену клавиатуры тахографа;</li><br>
                  <li>замену материнской платы тахографа;</li><br>
                  <li>замену батарейки тахографа.</li>
                </ul>
              </p>
              <p>
                Частота проведения планового обслуживания тахографов составляет
                один раз в три года для тахографов российского производства,
                а вот их внеплановые проверки могут быть проведены одновременно
                с техосмотром транспортных средств, ремонтом КПП, заменой датчика скорости,
                заменой шин или сменой регистрационных данных автомобилей,
                на которых были установлены устройства.
              </p>
              <p>
                Результатом проверки и обслуживания тахографов является подтверждение
                его пригодности к дальнейшему использованию прибора. Также выполняется
                его перенастройка, смена установочной таблички и выдача соответствующего
                документа, свидетельствующего о выполненных операциях.
              </p>
              <br><br>
              <h3 align="center">Замена блока СКЗИ в тахографе</h3>
              <br>
              <p>
                Срок действия криптографических ключей НКМ составляет 3 года,
                по истечению данного срока блок СКЗИ в тахографе должен быть заменен.
              </p>
              <p>
                В процессе замены проводится демонтаж тахографа, деактивация старого блока,
                замена и активация нового НКМ, монтаж тахографа и калибровка тахографа.
                В стоимость включен весь комплекс работ.
              </p>
              <p>Все работы производятся на основании Лицензии ФСБ России.</p>
              <p>
                Один раз в три года подлежат обязательной замене блоки СКЗИ в
                тахографах VDO 3283, Атол Drive, Штрих Тахо RUS, Меркурий ТА 001, КАСБИ ДТ 20М.
              </p>
              <p>
                Программно-аппаратное шифровальное (криптографическое) средство
                (блок СКЗИ тахографа) «Навигационно-криптографический модуль «НКМ-2.11»
                (далее – НКМ) является компонентом тахографа, предназначенным для
                реализации криптографических алгоритмов, необходимых для вычисления
                квалифицированной электронной подписи, проведения процедур аутентификации
                и обеспечения защиты информации, обрабатываемой и хранимой в тахографе
                и подлежащей защите в соответствии с законодательством Российской Федерации.
              </p>
              <p>Блоки СКЗИ поставляются метрологически поверенными с соответствующими отметками в формуляре.</p>
              <br><br>
              <h3 align="center">Калибровка (настройка) тахографов с СКЗИ</h3>
              <br>
              <p>
                Сроки калибровки тахографа: в соответствии с российским законодательством
                все тахографы подлежат обязательной периодической калибровке.
              </p>
              <p>Тахографы с СКЗИ: необходимо проводить калибровку - не реже 1 раза в 3 года.</p>
              <p>Срок калибровки каждого конкретного тахографа определяется заводом производителем и указывается в паспорте.</p>
              <p>Внеочередная калибровка тахографа.</p>
              <p>
                Также необходимо производить внеочередные процедуры проверки и калибровки тахографа в следующих случаях:<br><br>
                <ul>
                  <li>при снятии тахографа с ТС для его ремонта;</li><br>
                  <li>при погрешности определения времени более чем ±20 минут;</li><br>
                  <li>
                    при изменении любого из регистрируемых параметров ТС: VIN, страны регистрации,
                    гос. рег. знака (перестановка тахографа на другое ТС, смена владельца ТС);
                  </li><br>
                  <li>
                    при внесении изменений в ТС, влияющих на коэффициент W, в том числе:
                    при смене шин ведущих колес, к которым подключен датчик движения,
                    ремонта трансмиссии, оказывающего влияние на датчик движения, ремонта
                    кабельного соединения тахографа и датчика, ремонта/замены самого датчика;
                  </li><br>
                  <li>при срыве пломб и обнаружении манипуляций.</li>
                </ul>
              </p>
              <br>
              <h3 align="center">Процесс проведения калибровки тахографа</h3>
              <br>
              <p>И регулярные и дополнительные калибровки производятся в одинаковой последовательности.</p>
              <p>
                Прежде чем начать процедуру самой калибровки, проводится:<br><br>
                <ul>
                  <li>визуальный осмотр тахографа, датчика движения и кабеля, их соединяющего на наличие механических повреждений;</li><br>
                  <li>проверка состояния всех пломб системы тахограф-датчик и калибровочной таблички;</li><br>
                  <li>проверка отсутствий манипуляций с системой;</li><br>
                  <li>проверка исправности тахографа и системы в целом (датчика движения, кабеля);</li><br>
                  <li>резервное копирование информации с тахографа.</li><br>
                </ul>
              </p>
              <p>
                Затем проводится процедура самой калибровки тахографа, в процессе
                которой прокатывается ТС на определенное расстояние и рассчитывается
                количество импульсов, принятое от датчика движения за время прохождения
                этого расстояния. Все полученные результаты вносятся в память тахографа.
                После чего выдается калибровочный акт и клеится новая калибровочная табличка.
              </p>
              <br><br>
              <h3 align="center">Карта водителя для тахографа с СКЗИ</h3>
              <br>
              <p>
                Карта водителя СКЗИ - является персональным документом профессионального водителя,
                осуществляющего перевозки пассажиров или грузов по территории России.
                Карта обеспечивает идентификацию водителя с использованием шифровальных
                (криптографических) средств, а также хранение данных о режиме труда и отдыха водителя.
              </p>
              <p>Личная карточка водителя активирует в тахографе рабочий режим - режим вождения транспортного средства.</p>
              <p>
                Карты тахографа с СКЗИ могут оформить как граждане России, так и
                иностранные граждане, осуществляющие перевозку пассажиров или грузов на территории РФ.
              </p>
              <p>
                Срок действия персональной карточки водителя - три года, по истечению
                данного срока карту тахографа необходимо перевыпустить.
              </p>
              <p>
                Для заказа карты водителя для тахографа с СКЗИ необходимы оригиналы следующих документов:<br><br>
                <ul>
                  <li>паспорт (страница с фотографией, страница с пропиской);</li><br>
                  <li>водительское удостоверение;</li><br>
                  <li>СНИЛС;</li><br>
                  <li>ИНН;</li><br>
                  <li>Фотография 3,5 х 4,5.</li><br>
                </ul>
              </p>
              <br><br>
              <h3 align="center">Карта водителя для тахографа ЕСТР</h3>
              <br>
              <p>
                Карта водителя ЕСТР (Евро) - является персональным документом
                профессионального водителя, осуществляющего международные перевозки
                пассажиров или грузов. Карта обеспечивает идентификацию водителя с
                использованием шифровальных средств, а также хранение данных о
                режиме труда и отдыха водителя.
              </p>
              <p>
                Карта водителя ЕСТР совместима со следующими европейскими тахографами:
                VDO DTCO 1381, Stoneridge SE5000 и другими марками европейских
                цифровых тахографов, отвечающих требованиям ЕСТР.
              </p>
              <p>
                Для заказа карты водителя для тахографа ЕСТР (ЕВРО) необходимы оригиналы следующих документов:<br><br>
                <ul>
                  <li>паспорт (страница с фотографией и страница с пропиской);</li><br>
                  <li>водительское удостоверение;</li><br>
                  <li>СНИЛС;</li><br>
                  <li>ИНН;</li><br>
                  <li>фотография 3,5 х 4,5.</li><br>
                </ul>
              </p><br><br>
              <v-divider></v-divider>
              <br><br><br><br>
              <h1 align="center">Частые вопросы</h1>
              <br>
              <h3 class="subtitle">Как долго будут делать мою карту?</h3>
              <br>
              <p>Как правило, изготовление карты занимает не более 14 дней.</p>
              <h3 class="subtitle">Как осуществить перевыпуск карты?</h3>
              <br>
              <p>
                Предоставьте документы, необходимые для первичного выпуска карты.
                Срок действия новой карты начнется с момента окончания срока
                действия старой. Карта выдается на три года.
              </p>
              <h3 class="subtitle">Что делать при поломке и потере карты?</h3>
              <br>
              <p>
                Поломка<br>
                <ul>
                  <li>Карта может быть перевыпущена. В этом случае вам необходимо подать стандартную заявку на перевыпуск карты.</li><br>
                  <li>Обязательно приложите фото или скан поломанной карты.</li>
                </ul>
                <br>
                Утеря<br>
                <ul>
                  <li>В случае утери карты заполните соответствующую форму заявки.</li>
                </ul>
              </p>
              <h3 class="subtitle">Какие бывают разновидности карт?</h3>
              <br>
              <p>
                В зависимости от тахографа, установленного в автомобиле, тахокарты также бывают разные (российского и европейского стандарта).<br>
                Рассмотрим их разновидности подробнее:<br>
                <ul>
                  <li>карта водителя (служит для записи данных с тахографа);</li><br>
                  <li>карта предприятия (предоставляет доступ для анализа данных);</li><br>
                  <li>карта контролера (контроль данных в режиме проверок со стороны госорганов);</li><br>
                  <li>карта мастера (необходима при настройке всех рабочих режимов прибора — калибровка, монтаж и т. д.).</li><br>
                </ul>
              </p>
              <br><br>
              <v-divider></v-divider>
              <br><br><br><br>
              <v-row>
                <v-col align="center">
                  <v-img src="img/cds_logo.png" max-width="200px"></v-img>
                </v-col>
                <v-col>
                  <span>МБУ “ЦДС ГПТ”</span><br>
                  <span>ИНН 4345065415</span><br>
                  <span>610004, г. Киров, ул. Пятницкая, д. 2а</span>
                </v-col>
                <v-col>
                  (8332) <a href="#">48-95-50</a><br>
                  <!-- , <a href="#">75-19-30</a><br> -->
                  <a href="mailto:tex@cds43.ru">tex@cds43.ru</a><br>
                  <!-- <a href="#">+7 (922) 912-34-90</a> -->
                </v-col>
              </v-row>
            </v-card>
          </v-flex>
        </v-card>
      </v-layout>
    </v-main>
  </v-container>
</template>

<script>
export default {
  name: 'Main',
  data: () => ({
    slides: [
      { 'id': 1, 'alt': 'Здание МБУ "ЦДС ГПТ"', 'src': 'img/slide1.jpg'},
      { 'id': 2, 'alt': 'Автомобили', 'src': 'img/slide2.jpg'},
      { 'id': 3, 'alt': 'Мастерская по ремонту', 'src': 'img/slide3.jpg'},
      { 'id': 4, 'alt': 'Настройка тахографов', 'src': 'img/slide4.jpg'},
      { 'id': 5, 'alt': 'Лицензия ФСБ', 'src': 'img/slide5.jpg'}
    ],
    form: {}
  }),
  methods: {
    getStyle(height) {
      return {flex: `1 1 ${height}%`, 'max-height': `${height}%` }
    },
    async sendRequest () {
      console.log(this.form)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-form {
  width: 400px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: disc;
  padding: 0;
}
li {
  margin: 0 20px;
}
.main-title {
  margin-top: 60px;
  margin-left: 20px;
  font-size: 2.5em;
  color: #FFFF;
  text-align: left;
}
p {
  padding: 15px;
  padding-top:0px;
  font-size: 20px;
  font-family: sans-serif;
  text-align: left;
}
h1 {
  font-size: 40px;
  line-height: 45px;
  font-family: sans-serif;
}
h3 {
  font-size: 30px;
  line-height: 35px;
}
h3.subtitle {
  padding-left: 15px;
  font-style: italic;
}
h3.cardtitle {
  font-size: 25px;
  padding-left: 10px;
  padding-right: 10px;
}
h2 {
  font-size: 35px;
  line-height: 40px;
}
.chip {
  background-color: #42A5F5;
  height: 60px;
  width: 60px;
  border-radius: 60px;
  color: #FFFF;
  font-size: 20px;
}
.card {
  border-style: solid;
  border-color: #42A5F5;
  margin-left: 15px;
  margin-right: 15px;
}
.contact {
  font-size: 1.5em;
}
/* Поплавок четыре колонки бок о бок */
</style>
